import request  from '../request'
import { BASE_URL } from '../../common/keys';

const goods = {
  // 分页查询商品列表
  getGoods (params) {
    return request.get(`${BASE_URL}/goods/selectPage`, params);
  },
  // 获取商品详细内容信息
  getGoodsDetail (params) {
    return request.get(`${BASE_URL}/goods/getGoodsDetail`, params);
  },
}

export default goods;
