import request  from '../request'
import { BASE_URL } from '../../common/keys';

const user = {
  // 查询当前项目楼号信息
  getBuildingNumbers () {
    return request.get(`${BASE_URL}/project/getBuildingNumbers`);
  },
  // 设置用户的默认选中的房产
  setDefaultOwnerBuilding (params) {
    return request.get(`${BASE_URL}/owner/setDefaultOwnerBuilding`, params);
  },
  // 获取用户信息
  getUser () {
    return request.get(`${BASE_URL}/user/getOwnerUserInfo`);
  },
  // 更新业主信息
  getUpdateById (data) {
    return request.post(`${BASE_URL}/owner/updateById`, data);
  },
  // 业主信息认证
  getOwner (data) {
    return request.post(`${BASE_URL}/owner/confirmOwnerInfo`, data);
  },
  // 查询房产
  getCurrentOwnerBuildings () {
    return request.get(`${BASE_URL}/owner/getCurrentOwnerBuildings`);
  },
  // 新增房产
  getAddOwnerBuilding (data) {
    return request.post(`${BASE_URL}/owner/addOwnerBuilding`, data);
  },
  // 删除房产
  getDeleteOwnerBuildingById (params) {
    return request.delete(`${BASE_URL}/owner/deleteOwnerBuildingById`, params);
  },
  // 查询车辆
  getCurrentOwnerCars () {
    return request.get(`${BASE_URL}/owner/getCurrentOwnerCars`);
  },
  // 新增车辆
  getAddOwnerCar (data) {
    return request.post(`${BASE_URL}/owner/addOwnerCar`, data);
  },
  // 删除车辆
  getDeleteOwnerCarById (params) {
    return request.delete(`${BASE_URL}/owner/deleteOwnerCarById`, params);
  },
  // 查询当前用户所有账号
  getUserAllAccount () {
    return request.get(`${BASE_URL}/owner/getUserAllAccount`);
  },
  // 删除业主账号
  getDeleteById (params) {
    return request.delete(`${BASE_URL}/owner/deleteById`, params);
  },
  // 新增业主账号
  getAddSubOwner (data) {
    return request.post(`${BASE_URL}/owner/addSubOwner`, data);
  },
  // 查询当前用户的车位收藏记录
  getUserFollowRecord () {
    return request.get(`${BASE_URL}/parkingspace/getUserFollowRecord`);
  },
  // 删除收藏停车位
  getDelParkingCollection (params) {
    return request.get(`${BASE_URL}/parkingspace/delParkingCollection`, params);
  },
  // 文件上传
  getUploadFile (data) {
    return request.upload(`${BASE_URL}/file/uploadFile`, data);
  },
  // 更新业主头像
  getChangeOwnerIcon (params) {
    return request.get(`${BASE_URL}/owner/changeOwnerIcon`, params);
  },
  // 置业顾问
  getSelectPage (params) {
    return request.get(`${BASE_URL}/consultant/selectPage`, params);
  },
  // 获取车辆类型列表
  getVehicleTypeList () {
    return request.get(`${BASE_URL}/parkingspace/getVehicleTypeList`);
  },
}

export default user;
