import request  from '../request'
import { BASE_URL } from '../../common/keys';

const home = {
  // 轮播图管理 图片类型：0-启动页，1-项目轮播图，2-选车位背景
  getSelectPage (params) {
    return request.get(`${BASE_URL}/banner/getBannerByType`,params);
  },
  // 弹幕管理
  getFakeBarrageInfos () {
    return request.get(`${BASE_URL}/barrage/getFakeBarrageInfos`);
  },
  // 首页数据
  getHomeData () {
    return request.get(`${BASE_URL}/getHomeData`);
  },
  // 项目活动分页查询
  getNewSelectPage (params) {
    return request.get(`${BASE_URL}/news/selectPage`, params);
  }
}

export default home;
