import { CommonMutationTypes } from './mutation-types'
import { CommonActionTypes } from './action-types'

export const actions = {
  async [CommonActionTypes.SET_TABBAR_SHOW]({commit},payload) {
    commit(CommonMutationTypes.SET_TABBAR_SHOW,payload)
  },

  async [CommonActionTypes.SET_NAV_BAR]({commit},payload) {
    commit(CommonMutationTypes.SET_NAV_BAR,payload)
  },
  
  async [CommonActionTypes.SET_MAP_LOADING_SHOW]({commit},payload) {
    commit(CommonMutationTypes.SET_MAP_LOADING_SHOW,payload)
  },

  async [CommonActionTypes.SET_MAP_LOADING_NUM]({commit},payload) {
    commit(CommonMutationTypes.SET_MAP_LOADING_NUM,payload)
  },
}
