import request  from '../request'
import { BASE_URL } from '../../common/keys';

const userTrace = {
  // 新增埋点数据
  addUserTrace (params) {
    return request.post(`${BASE_URL}/userTrace/addUserTrace`, params);
  },
}

export default userTrace;
