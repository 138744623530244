import request  from '../request'
import { BASE_URL } from '../../common/keys';



const law = {
    // 查询用户协议
    getUserAgreement () {
        return request.get(`${BASE_URL}/law/getUserAgreement`);
    },
    // 查询隐私条款
    getPrivacyPolicy () {
        return request.get(`${BASE_URL}/law/getPrivacyPolicy`);
    },
    // 查询3D条款
    get3DPolicy () {
        return request.get(`${BASE_URL}/law/get3DPolicy`);
    },
    // 查询车位购买条款
    getPurchaseAgreement () {
        return request.get(`${BASE_URL}/law/getPurchaseAgreement`);
    },
}

export default law;
