import request  from '../request'
import { BASE_URL } from '../../common/keys';

const coupon = {
  // 查询业主所有优惠券
  getCoupons () {
    return request.get(`${BASE_URL}/owner/getCoupons`);
  },
}

export default coupon;
