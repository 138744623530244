import { store as user } from './modules/user'
import { store as common } from './modules/common'


export const store = {
  namespaced: true,
  modules: {
    user,
    common,
  },
}
