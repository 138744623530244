import auth from './auth'
import goods from './goods'
import order from './order'
import project from './project'
import home from './home'
import user from './user'
import coupon from './coupon'
import startup from './startup'
import trace from './trace'
import law from './law'
import psp from './psp'

export default {
    auth,
    goods,
    order,
    project,
    home,
    user,
    coupon,
    startup,
    trace,
    law,
    psp
}