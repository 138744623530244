import request  from '../request'
import { BASE_URL } from '../../common/keys';

const startup = {
  // 获取启动图
  getStartupDiagram () {
    return request.get(`${BASE_URL}/banner/getStartupDiagram`);
  },
}

export default startup;
