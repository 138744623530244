const COMMON_DES_KEY='YFrXmUH7fVvmU3Ze'
const ACCESS_TOKEN_KEY='AccessToken'
const BASE_URL='/parking'
const ACTIVE_DETAIL = 'active_detail'
const TEMP_ORDER = 'temp_order'
const PAY_ORDER = 'pay_order'
const CHOOSE_BUILDING_NO = 'choose_building_no'
const PAY_CB = 'PAY_CB'
const PAY_CACHE_DATA = 'PAY_CACHE_DATA'
const ORDER_ACTIVE_TAB = 'ORDER_ACTIVE_TAB'
export {
  COMMON_DES_KEY,
  ACCESS_TOKEN_KEY,
  BASE_URL,
  ACTIVE_DETAIL,
  TEMP_ORDER,
  PAY_ORDER,
  CHOOSE_BUILDING_NO,
  PAY_CB,
  PAY_CACHE_DATA,
  ORDER_ACTIVE_TAB
}