import request  from '../request'
import { BASE_URL } from '../../common/keys';

const project = {
  // 获取订单列表
  getInfo () {
    return request.get(`${BASE_URL}/project/getInfo`);
  },
}

export default project;
