import { UserMutationTypes } from './mutation-types'

export const mutations = {
  [UserMutationTypes.SET_USER_INFO](state,payload) {
    state.info = payload
    if (payload != null) {
      state.isLogin = true
      state.project = payload.prjInfo
    } else {
      state.isLogin = false
      state.project = undefined
    }
  },
  [UserMutationTypes.SET_PROJECT_INFO](state, prjInfo) {
    state.project = prjInfo
    state.info.project = prjInfo
  },
  [UserMutationTypes.SET_AGREEMENT_PSP_TERMS](state, flag) {
    state.agreementPspTerms = flag
  },
}
