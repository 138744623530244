import request  from '../network/request'
let tasks = []
const task = () => {
  if(tasks.length > 0) {
    const url = tasks.pop();
    request.cache(`${url}`).then(() => {
    },() => {}).finally(() => {
      task();
    });
  }
}

const start = () => {
  request.getJSON('/json/CacheTasks.json').then(data => {
    tasks = data;
    task();
  })
}

export default {
  start
};