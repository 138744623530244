import axios from 'axios'
import qs from 'qs'
import { Toast } from 'vant';
import {ACCESS_TOKEN_KEY,COMMON_DES_KEY} from './../common/keys'
import Tools from "@/assets/js/tools";
import { router } from '@/router'



// 避免全局修改导致其他框架问题
const modifyPromise = (promise) => {
  promise.oldThe = promise.then;
  promise.then = function(onFulfilled, onRejected) {
    return promise.oldThe(onFulfilled, onRejected || onError);
  }
  return promise;
}

const NEED_SIGN_IN = "NEED_SIGN_IN";

const onError = (res) => {
  if(res.state === NEED_SIGN_IN) {
    Tools.removeLocal();
    Toast({
      message: '请重新登录！',
      onClose: () => {
        router.push('/login');
      }
    });
  } else {
    Toast(res.msg || defaultError().msg);
  }
}

const defaultError = (state, msg) => {
  if(state === NEED_SIGN_IN) {
    Tools.removeLocal();
    Toast({
      message: '请重新登录！',
      onClose: () => {
        router.push('/login');
      }
    });
  }
  return {
    state: `${state || 'fail'}`,
    msg: msg || '网络出了小差，请稍后再试。',
  }
}

const service = axios.create({
  baseURL: process.env.BASE_URL,
  timeout: 1000*60*5
});

// request拦截器==>对请求参数做处理
service.interceptors.request.use(config => {
  if(!config.headers.Authorization){
    const token =  getToken();
    if(token) {
      config.headers.Authorization = token;
    }
  }
  return config;
}, error => {
  console.error(error);
  return Promise.reject(defaultError())
});

// respone拦截器==>对响应做处理
service.interceptors.response.use(
  res => {
    if(res.config.isCache) {
      return;
    }
    if (res.status >= 200 &&
      res.status < 300) {
      if (res.config.isJSON === true) {
          return res.data;
      }
      if (res.data && res.data.state) {
        switch (res.data.state) {
          case 'success':
            return res.data.obj;
          case 'SF10004':
          case 'SF10005':
            return Promise.reject(defaultError(NEED_SIGN_IN));
          case 'SF10000':
            return Promise.reject(defaultError(res.data.state, res.data.msg || '无权限'));
          default:
            return Promise.reject(defaultError(res.data.state,res.data.msg));
        }
      }
    }
    return Promise.reject(defaultError(res.status));
  },
  error => {
    if(error.response && error.response.status === 401) {
      return Promise.reject(defaultError(NEED_SIGN_IN));
    }
    return Promise.reject(defaultError(error.response.state));
  }
);

const getReqConfig = (opt, type) => {
  let _config = {headers:{}};
  if(opt){
    if(opt.headers && opt.headers.Authorization){
      _config.headers.Authorization = opt.headers.Authorization;
    }
  }
  if('form' == type){
    _config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
  }else if('file' == type){
    _config.headers['Content-Type'] = 'multipart/form-data;charset=UTF-8';
    _config.timeout = 1000 * 60 * 60; // 单位毫秒
  }
  return _config;
}

const getToken = ()=>{
  const accessToken = Tools.getLocal(COMMON_DES_KEY,ACCESS_TOKEN_KEY);
  if(!accessToken) {
    return '';
  }
  return `Bearer ${accessToken}`;
}

const request = {
  get: (url, params, opt) => {
    let symbol = url.indexOf('?') > -1 ? '&' : '?';
    url += `${symbol}t=${Math.random()}`;
    let config = getReqConfig(opt);
    if (params) {
      config.params = params,
        config.paramsSerializer = params => {
          return qs.stringify(params, { indices: false })
        }
    }

    return  modifyPromise(new Promise((resolve, reject) => {
      service.get(url, config)
        .then(res => resolve(res))
        .catch(err => {
          reject(err)
        })
    }));
  },
  post : (url, data, opt) => {
    let symbol = url.indexOf('?') > -1 ? '&' : '?';
    url += `${symbol}t=${Math.random()}`;
    return modifyPromise(new Promise((resolve, reject) => {
      service.post(url, data, getReqConfig(opt))
        .then(res => resolve(res))
        .catch(err => {
          reject(err)
        })
    }));
  },
  delete: (url, params, opt) => {
    let symbol = url.indexOf('?') > -1 ? '&' : '?';
    url += `${symbol}t=${Math.random()}`;
    let config = getReqConfig(opt);
    if (params) {
      config.params = params,
        config.paramsSerializer = params => {
          return qs.stringify(params, { indices: false })
        }
    }

    return  modifyPromise(new Promise((resolve, reject) => {
      service.delete(url, config)
        .then(res => resolve(res))
        .catch(err => {
          reject(err)
        })
    }));
  },
  // 适用于Content-Type'为'application/x-www-form-urlencoded post请求
  postForm : (url, data, opt) => {
    let symbol = url.indexOf('?') > -1 ? '&' : '?';
    url += `${symbol}t=${Math.random()}`;
    return modifyPromise(new Promise((resolve, reject) => {
      service.post(url, qs.stringify(data), getReqConfig(opt, 'form'))
        .then(res => resolve(res))
        .catch(err => {
          reject(err)
        })
    }));
  },
  upload : (url, data, opt) => {
    let symbol = url.indexOf('?') > -1 ? '&' : '?';
    url += `${symbol}t=${Math.random()}`;
    const forms = new FormData();
    for(let k in data) {
      forms.append(k, data[k]);
    }
    return modifyPromise(new Promise((resolve, reject) => {
      service.post(url, forms, getReqConfig(opt, 'file'))
        .then(res => resolve(res))
        .catch(err => {
          reject(err)
        })
    }));
  },
  getJSON : (url)=>{
    return service.get(url, {isJSON: true});
  },
  cache : (url)=>{
    return service.get(url, {isCache: true});
  }
}

export default request;