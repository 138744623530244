import request  from '../request'
import { BASE_URL } from '../../common/keys';

const order = {
  // 获取订单列表
  getOrders (params) {
    return request.get(`${BASE_URL}/order/getOrders`, params);
  },
  // 查询订单信息
  getOrderInfo (params) {
    return request.get(`${BASE_URL}/order/${params.orderId}`);
  },
  // 商品结算
  buyGoods (params) {
    return request.post(`${BASE_URL}/order/buyGoods`, params);
  },
  // 订单价格计算
  getOrderPrice (params) {
    return request.post(`${BASE_URL}/order/getOrderPrice`, params);
  },
  // 保存订单
  saveOrder (params) {
    return request.post(`${BASE_URL}/order/saveOrder`, params);
  },
  // 取消订单
  cancelOrder (params) {
    return request.get(`${BASE_URL}/order/cancelOrder`, params);
  },
  // 用户订单退款
  refundOrder (params) {
    return request.get(`${BASE_URL}/order/refundOrder`, params);
  },
  // 支付项目意向金订单
  saveIntentionOrder (params) {
    return request.post(`${BASE_URL}/order/saveIntentionOrder`, params);
  },
  // 生成订单支付连接
  getWxPayUrl (params) {
    return request.post(`${BASE_URL}/order/getWxPayUrl`, params);
  },
  // 微信支付完成回调
  wxPayNotify () {
    return request.get(`${BASE_URL}/order/wxPayNotify`);
  },
  // 分页查询指定用户的购物车商品清单
  getShoppingItems (params) {
    return request.get(`${BASE_URL}/order/getShoppingItems`, params);
  },
  // 计算购物车商品价格
  getShoppingGoodsPrice (params) {
    return request.post(`${BASE_URL}/order/getShoppingGoodsPrice`, params);
  },
  // 获取订单可用优惠券
  getOrderAvailableCoupon (params) {
    return request.post(`${BASE_URL}/order/getOrderAvailableCoupon`, params);
  },
  // 商品加入购物车
  addOrderItem (params) {
    return request.post(`${BASE_URL}/order/addOrderItem`, params);
  },
  // 购物车中的商品购买数量
  changeOrderItemSaleCount (params) {
    return request.get(`${BASE_URL}/order/changeOrderItemSaleCount`, params);
  },
  // 删除购物车中的商品
  delOrderItem (params) {
    return request.delete(`${BASE_URL}/order/delOrderItem`, params);
  },
}

export default order;
