import { createRouter, createWebHashHistory } from 'vue-router';
import { ACCESS_TOKEN_KEY,COMMON_DES_KEY,PAY_CACHE_DATA } from 'common/keys'
import Tools from "@/assets/js/tools";
import trace from './common/trace';
import pay from 'common/pay'
import utils from './common/utils';

const routes = [
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'count',
    },
  },
  {
    name: 'count',
    path: '/count',
    component: () => import('./view/login/countDown'),
    meta: {
      title: '',
      isSkipRemainTrace: true,
      isNotNeedSignIn: true,
    },
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('./view/login/index'),
    meta: {
      title: '登录',
      keepAlive: true,
      isSkipRemainTrace: true,
      isNotNeedSignIn: true,
    }
  },
  {
    name: 'building',
    path: '/building',
    component: () => import('./view/login/building'),
    meta: {
      title: '选择楼栋',
    },
  },
  {
    name: 'newAuthent',
    path: '/newAuthent',
    component: () => import('./view/login/newAuthent'),
    meta: {
      title: '认证',
    },
  },
  {
    name: 'UserGgreement',
    path: '/UserGgreement',
    component: () => import('./view/login/UserGgreement'),
    meta: {
      title: '条款协议',
      keepAlive: true,
      isNotNeedSignIn: true,
      isSkipRemainTrace: true,
    },
  },
  {
    name: 'home',
    path: '/home',
    component: () => import('./view/home/index'),
    meta: {
      title: '首页',
      keepAlive: true
    },
  },
  {
    name: 'details',
    path: '/details',
    component: () => import('./view/home/details'),
    meta: {
      title: '活动详情',
      keepAlive: true,
    },
  },
  {
    name: 'shop',
    path: '/shop',
    component: () => import('./view/shop'),
    meta: {
      title: '商城',
      keepAlive: true
    },
  },
  {
    name: 'goods-detail',
    path: '/goods-detail',
    component: () => import('./view/shop/goods-detail'),
    meta: {
      title: '商品详情',
    },
  },
  {
    name: 'mine',
    path: '/mine',
    component: () => import('./view/mine'),
    meta: {
      title: '我的',
      keepAlive: true
    },
  },
  {
    name: 'order',
    path: '/order',
    component: () => import('./view/order'),
    meta: {
      title: '我的订单',
    },
  },
  {
    name: 'order-detail',
    path: '/order-detail',
    component: () => import('./view/order/order-detail'),
    meta: {
      title: '订单支付',
    },
  },
  {
    name: 'order-detail-paid',
    path: '/order-detail-paid',
    component: () => import('./view/order/order-detail-paid'),
    meta: {
      title: '订单详情',
    },
  },
  {
    name: 'mortgage',
    path: '/mortgage',
    component: () => import('./view/order/mortgage'),
    meta: {
      title: '按揭计算',
      keepAlive: true,
    }
  },
  {
    name: 'payment-success',
    path: '/payment-success',
    component: () => import('./view/order/payment/payment-success'),
    meta: {
      title: '支付成功',
    }
  },
  {
    name: 'eareast-pay',
    path: '/eareast-pay',
    component: () => import('./view/order/payment/eareast-pay'),
    meta: {
      title: '意向金支付',
      keepAlive: true,
    }
  },
  {
    name: 'account',
    path: '/account',
    component: () => import('./view/accountNumber'),
    meta: {
      title: '个人信息',
    }
  },
  {
    name: 'user',
    path: '/user',
    component: () => import('./view/user/index'),
    meta: {
      title: '添加账号',
    }
  },
  {
    name: 'coupon',
    path: '/coupon',
    component: () => import('./view/coupon'),
    meta: {
      title: '我的优惠券',
    }
  },
  {
    name: 'collection',
    path: '/collection',
    component: () => import('./view/collection'),
    meta: {
      title: '我的收藏',
    }
  },
  {
    name: 'adviser',
    path: '/adviser',
    component: () => import('./view/adviser'),
    meta: {
      title: '置业顾问',
    }
  },
  {
    name: 'agreement',
    path: '/agreement',
    component: () => import('./view/agreement'),
    meta: {
      title: '用户协议',
      keepAlive: true,
      isNotNeedSignIn: true,
      isSkipRemainTrace: true,
    }
  },
  {
    name: 'purchase-agreement',
    path: '/purchase-agreement',
    component: () => import('./view/purchase-agreement'),
    meta: {
      title: '车位购买协议',
      keepAlive: true,
      isNotNeedSignIn: true,
    }
  },
  {
    name: 'policy',
    path: '/policy',
    component: () => import('./view/policy'),
    meta: {
      title: '隐私政策',
      keepAlive: true,
      isNotNeedSignIn: true,
      isSkipRemainTrace: true,
    }
  },
  {
    name: 'Pusheng',
    path: '/Pusheng',
    component: () => import('./view/Pusheng'),
    meta: {
      title: '关于普仕昇',
      keepAlive: true,
      isNotNeedSignIn: true,
    }
  },
  {
    name: 'edit',
    path: '/edit',
    component: () => import('./components/edit'),
    meta: {
      title: '编辑',
    }
  },
  {
    name: 'accountEdit',
    path: '/accountEdit',
    component: () => import('./components/accountEdit'),
    meta: {
      title: '个人信息编辑',
    }
  },
  {
    name: 'userAdd',
    path: '/userAdd',
    component: () => import('./view/user/userAdd'),
    meta: {
      title: '用户新增',
    }
  },
  {
    name: 'psp',
    path: '/parking-space-purchase',
    component: () => import('./view/psp'),
    meta: {
      title: '车位选购',
      keepAlive: true
    },
  }
];

const router = createRouter({
  routes,
  history: createWebHashHistory(),
});
let RemainTrace = undefined;
router.beforeEach((to, from, next) => {
  if(RemainTrace !== undefined) {
    trace.stopRemain(RemainTrace);
    RemainTrace = undefined;
  }
  if(to.meta && !to.meta.isSkipRemainTrace) {
    RemainTrace = trace.startRemain(to.meta.title,`访问${to.meta.title}页面`);
  } 
  const token = Tools.getLocal(COMMON_DES_KEY,ACCESS_TOKEN_KEY)
  if (to.meta.isNotNeedSignIn || (null !== token && undefined !== token)) {
    const title = to.meta && to.meta.title;
    if (title) {
      document.title = title;
    }
    next() 
  } else {
    next({path:'/login'})
  }
});

router.afterEach(() => {
  const payCb = Tools.getLocal(COMMON_DES_KEY,PAY_CACHE_DATA);
  if(null !== payCb && undefined !== payCb) {
    pay.dispatchEvent(utils.getQueryVariable('code'));
  }
})

export { router };
