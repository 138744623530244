import { createApp } from "vue";
import App from "./App.vue";
import { store, key } from "./store";
import { router } from "./router";
import { ConfigProvider } from "vant";
import api from '@/network/api'
import {
  Button, CountDown, Toast, Form, Field, CellGroup, Checkbox, CheckboxGroup, RadioGroup, Radio, Tag,
  PullRefresh, NavBar, Cell, Uploader, Icon, Tab, Tabs, Divider, Popover, ActionSheet, Swipe, SwipeItem, Dialog, Image as VanImage, Loading, Skeleton
} from 'vant';
import './assets/icon/icomoon/icomoon-icons.css';
import './assets/icon/awesome/awesome-icons.css';
const app = createApp(App);
app.config.globalProperties.$api = api;
app.use(store, key).use(router).use(ConfigProvider);
app.mount("#app");
app.use(Button);
app.use(CountDown);
app.use(Toast);
app.use(Form);
app.use(Field);
app.use(CellGroup);
app.use(Checkbox);
app.use(CheckboxGroup);
app.use(PullRefresh);
app.use(NavBar);
app.use(Cell);
app.use(Uploader);
app.use(Icon);
app.use(Tab);
app.use(Tabs);
app.use(Divider);
app.use(Popover);
app.use(ActionSheet);
app.use(Swipe);
app.use(SwipeItem);
app.use(Dialog);
app.use(RadioGroup);
app.use(Radio);
app.use(Tag);
app.use(VanImage);
app.use(Loading);
app.use(Skeleton);

