import { UserMutationTypes } from './mutation-types'
import { UserActionTypes } from './action-types'
import {ACCESS_TOKEN_KEY,COMMON_DES_KEY} from '../../../../../common/keys'
import api from '../../../../../network/api'
import Tools from "@/assets/js/tools";

export const actions = {
  async [UserActionTypes.SET_TOKEN]({commit},payload) {
    Tools.setLocal(COMMON_DES_KEY, ACCESS_TOKEN_KEY, payload);
    const info = await api.auth.info();
    commit(UserMutationTypes.SET_USER_INFO, info)
  },

  async [UserActionTypes.SIGN_OUT](
    { commit }
  ) {
    await api.auth.signOut();
    Tools.removeLocal();
    commit(UserMutationTypes.SET_USER_INFO, undefined)
  },
  [UserActionTypes.CHOOSE_PROJECT]({commit}, prjInfo){
    commit(UserMutationTypes.SET_PROJECT_INFO, prjInfo)
  }
}
