import { createStore, useStore as baseUseStore } from 'vuex'
import { store as base } from './modules/base'

export const key = Symbol()

export const store = createStore({
  modules: {
    base,
  },
})

export function useStore() {
  return baseUseStore(key)
}