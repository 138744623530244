import request  from '../request'
import { BASE_URL } from '../../common/keys';

const SIGN_IN_OPTS = {
    headers : {
        "Authorization": "Basic cGM6MTIzNDU2",
    }
};


const auth = {
    signIn (username,password) {
        return request.post(`${BASE_URL}/signIn`,{username,password},SIGN_IN_OPTS);
    },
    info () {
        return request.get(`${BASE_URL}/user/getOwnerUserInfo`);
    },
    signOut () {
        return request.post(`${BASE_URL}/signOut`);
    }
}

export default auth;
