export const defaultNavBar = () => {
  return {
    show: false,
    title: '',
    leftArrow: false,
    style: undefined,
    leftArrowFn: undefined
  }
}

export const defaultState = () => {
  return {
    tabbarShow: false,
    mapLoadShow:false,
    mapLoadingNum:0,
    navBar: defaultNavBar()
  }
}
export const state = defaultState()
