import api from 'network/api'
import { ACCESS_TOKEN_KEY } from 'common/keys'

const remainCache = {};
let remianIdx = 1;

const SECOND = 1000;

const fixNum = (num) => {
    if(num < 10) {
        return '0' + num;
    }
    return num;
}

const getCurrentTimeStr = (date = new Date()) => {
    return `${date.getFullYear()}-${fixNum(date.getMonth()+1)}-${fixNum(date.getDate())} ${fixNum(date.getHours())}:${fixNum(date.getMinutes())}:${fixNum(date.getSeconds())}`;
}

// 开始停留类型
const startRemain = (page,eventDesc, dataId,dataType,dataSnapshot,route = location.href.replace(location.origin,'')) => {
    let cahce = {
        page,
        eventDesc,
        dataId,
        dataType,
        dataSnapshot,
        route,
        option:2,
        startTime: getCurrentTimeStr(),
    }
    let key = 'key' + remianIdx;
    remainCache[key] = cahce;
    remianIdx++
    return key;
}

const stopRemain = (key) => {
    let cache = remainCache[key];
    let start = new Date(cache.startTime); 
    let end = new Date();
    let time = (end.getTime() - start.getTime()) / SECOND;
    cache.endTime = getCurrentTimeStr(end);
    cache.durationTime = time;
    api.trace.addUserTrace(cache).then(()=>{},()=>{});
    delete remainCache[key];
}

const click = (page,eventDesc, dataId,dataType,dataSnapshot,route = location.href.replace(location.origin,'')) => {
    let time = getCurrentTimeStr();
    let requestData = {
        page,
        eventDesc,
        dataId,
        dataType,
        dataSnapshot,
        route,
        option:0,
        startTime: time,
        endTime: time,
    }
    api.trace.addUserTrace(requestData).then(()=>{},()=>{});
}


export default {
    startRemain,
    stopRemain,
    click
}