const getQueryVariable = (variable) => {
  let query = location.search.substring(1);
  let vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return null;
}
/**
 * 防抖函数
 * @param {*} fn 
 * @param {*} delay 
 * @returns 
 */
function debounce (fn, delay) {
  let timer = null;
  return (...args) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(this, args);
    }, delay);
  };
}

function groupBy (list, prop) {
  let groups = [];
  if (list && list.length > 0) {
    list.forEach(l => {
      let group = JSON.stringify(l[prop]);
      groups[group] = groups[group] || [];
      groups[group].push(l);
    })
    return Object.keys(groups).map(g => {
      return groups[g];
    })
  }
}

/**
 * sliderToggle
 */
const slideToggle = {
  addClass (ele, cls) {
    if (!ele || !cls) return;
    cls = cls.trim();
    let cur_class = ele.className;

    cls.split(' ').forEach(item => {
      if (!item) return true;

      if (ele.classList) {
        ele.classList.add(item);
      } else {
        cur_class += ' ' + item;
      }
    });
    !ele.classList && (ele.className = cur_class);
  },
  removeClass (ele, cls) {
    if (!ele || !cls) return;
    cls = cls.trim();
    let cur_class = ' ' + ele.className + ' ';
    cls.split(' ').forEach(item => {
      if (!item) return true;

      if (ele.classList) {
        ele.classList.remove(item);
      } else {
        cur_class = cur_class.replace(' ' + item + ' ', '');
      }
    });
    !ele.classList && (ele.className = cur_class.trim());
  },
  getCSS (ele, prop) {
    return window.getComputedStyle(ele, null).getPropertyValue(prop) || '';
  }
};

export default {
  getQueryVariable,
  debounce,
  slideToggle,
  groupBy
}