import { CommonMutationTypes } from './mutation-types'
import { defaultNavBar } from './state'

export const mutations = {
  [CommonMutationTypes.SET_TABBAR_SHOW] (state, payload) {
    state.tabbarShow = payload
  },
  [CommonMutationTypes.SET_NAV_BAR] (state, payload) {
    const result = defaultNavBar();
    Object.keys(payload).forEach(key => {
      // eslint-disable-next-line no-prototype-builtins
      if (result.hasOwnProperty(key) && payload[key] !== undefined && payload[key] !== null) {
        result[key] = payload[key];
      }
    });
    state.navBar = result;
  },

  [CommonMutationTypes.SET_MAP_LOADING_SHOW] (state, payload) {
    state.mapLoadShow = payload
  },

  [CommonMutationTypes.SET_MAP_LOADING_NUM] (state, payload) {
    state.mapLoadingNum = payload
  },
}
